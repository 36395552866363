import React, { Component } from "react";
import { observer } from "mobx-react";
import * as _ from "lodash";
import "./index.less";

const EchartsReact = Loader.loadBaseComponent('EchartsReact');
const IconFont = Loader.loadBaseComponent("IconFont");

@Decorator.errorBoundary
@observer
class Tags extends Component {
  getOtionTem() {
    let { data, myColor, singleTag } = this.props;
    let total = _.sum(data.map(v => v.value || v.count)) || "";
    let singleData = [data[0], { name: "总数", value: total - data[0].value }];
    const option = {
      color: myColor,
      series: [
        {
          type: "pie",
          symbol: "circle",
          radius: ["80%", "100%"],
          center: ["50%", "50%"],
          hoverAnimation: false,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: !singleTag ? data : singleData
        }
      ]
    };
    return option;
  }

  render() {
    let { data, myColor, icon, singleTag } = this.props;
    let total = _.sum(data.map(v => v.value || v.count)) || "";
    return (
      <div className="tags-chart">
        <EchartsReact option={this.getOtionTem()} style={{ height: "100px", width: "100px" }} />
        <div className="tags-chart-legends" style={{ width: "150px" }}>
          {!singleTag &&
            data.map((v, x) => (
              <div key={x}>
                <i style={{ backgroundColor: myColor[x] }} className="tags-chart-symbolC" />
                <span className="name">{v.name}：</span>
                <span className="num">{v.value}</span>
              </div>
            ))}
          <div className="tags-chart-center">
            <IconFont className="tags-icon" type={`${icon}`} />
            {singleTag ? "" : <span className="total">{total}</span>}
          </div>
        </div>
        {singleTag && (
          <div className="singleTag">
            <span className="total">{total > 0 ? parseInt((data[0].value / total) * 100) : 0}%</span>
            {data[0].name}
          </div>
        )}

        <style jsx="true">{`
          .tags-chart .tags-chart-center {
            position: absolute;
            top: ${singleTag ? "33px" : "25px"};
            left: 34px;
          }
        `}</style>
      </div>
    );
  }
}
export default Tags;
