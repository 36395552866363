/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { cloneDeep } from "lodash";
import { message } from "antd";
const EchartsReact = Loader.loadBaseComponent("EchartsReact");

let options = {
  tooltip: {
    formatter: function(x) {
      return x.data.des;
    }
  },
  series: [
    {
      type: "graph",
      layout: "force",
      symbolSize: 80,
      roam: false,
      edgeSymbol: ["circle", "arrow"],
      draggable: true,
      edgeSymbolSize: [4, 10],
      cursor: "pointer",
      // focusNodeAdjacency: true,
      // symbolKeepAspect:true,
      animation:false,
      force: {
        repulsion: 1200,
        edgeLength: [100, 250],
        layoutAnimation: false
      },
      itemStyle: {
        normal: {
          shadowColor: "rgba(80,233,178, 0.5)",
          shadowBlur: 10,
          fontSize: 16
        }
      },
      lineStyle: {
        normal: {
          width: 2,
          color: "#50E9B2"
        },
        label: {
          fontSize: 12
        }
      },
      edgeLabel: {
        normal: {
          show: true,
          formatter: function(x) {
            return x.data.name;
          },
          textStyle: {
            fontSize: 12
          }
        }
      },
      label: {
        normal: {
          show: true,
          position: "bottom",
          textStyle: {
            position: "bottom",
            fontSize: "12",
            color: "#000"
          }
        }
      },
      data: [],
      links: []
    }
  ]
};

/**
 * @description list dataType  数据类型
 *
 *
 * dataType  0 同屋  1 同行  2 车辆 3 设备 4 唱去地
 */

@Decorator.businessProvider("tab", "user", "place")
class PersonRoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: cloneDeep(options)
    };
    this.echartRef = React.createRef();
  }
  componentWillMount() {
    this.init();
  }

  init = () => {
    let { data, list } = this.props;
    list.forEach(v => {
      if (v.accoompanyAid) {
        // v.personId = v.accoompanyAid
        v.dataType = 1;
      } else {
        v.dataType = 0;
      }
      v.personName = v.personName || v.accoompanyAid;
    });
    if (data.vehicles.length) {
      data.vehicles.forEach(v => {
        v.personId = v.vehiclePlateNo;
        v.personName = v.vehiclePlateNo;
        v.portraitPictureUrl = v.vehicleUrl;
        v.dataType = 2;
        list.push(v);
      });
    }
    let frequently7 = data.Frequently7.places || [];
    let frequently30 = data.Frequently30.places || [];
    if (frequently7.length) {
      frequently7.forEach(v => {
        v.personId = v.placeName;
        v.personName = v.placeName;
        v.dataType = 4;
        v.placeType = 1;
        v.labelLine = "7天常去地";
        list.push(v);
      });
    }
    if (frequently30.length) {
      frequently30.forEach(v => {
        v.personId = v.placeName;
        v.personName = v.placeName;
        v.dataType = 4;
        v.labelLine = "30天常去地";
        v.placeType = 2;
        list.push(v);
      });
    }
    if (Object.keys(data.cidRelation).length > 0) {
      const { cidRelation = {} } = data;
      cidRelation.firstCid &&
        list.push({
          dataType: 3,
          snapType: 1,
          firstCaptureTime: cidRelation.firstCaptureTime,
          personId: cidRelation.firstCid,
          personName: cidRelation.firstDeviceName,
          labelLine: "首次出现"
        });
      cidRelation.lastCid &&
        list.push({
          dataType: 3,
          snapType: 2,
          personId: cidRelation.lastCid,
          personName: cidRelation.lastDeviceName,
          labelLine: "最近出现"
        });
      const maxDevices = cidRelation.maxDevices || [];
      maxDevices.forEach((v, k) => {
        if (k > 2) {
          return;
        }
        v.personId = v.cid;
        v.personName = v.deviceName;
        v.dataType = 3;
        v.snapType = 3;
        v.labelLine = "经常出现";
        list.push(v);
      });
    }
    this.getOtionTem(this.props.data, list);
  };
  //定义抠图方法
  async getImgData(item, color) {
    let img;
    let imgSrc = item.url;
    let type = item.type;
    try {
      img = await Utils.loadImage(imgSrc, true);
    } catch (e) {
      let errSrc = "/resource/image/AvatarDefault.svg";
      if (type === "vehicle") {
        errSrc = "/resource/image/CarDefault.svg";
      }
      if (type === "equipment") {
        errSrc = "/resource/image/VideoDefault.svg";
      }
      if (type === "palce") {
        errSrc = "/resource/image/FootDefault.svg";
      }
      img = await Utils.loadImage(errSrc, true);
    } finally {
      return this.canvasImage(img, color);
    }
  }
  canvasImage = (img, color = "#50E9B2") => {
    const canvas = document.createElement("canvas");
    const contex = canvas.getContext("2d");
    let size = 0,
      flag = true;
    if (img.width > img.height) {
      size = img.height + 20;
    } else {
      flag = false;
      size = img.width + 20;
    }
    canvas.width = size + 8;
    canvas.height = size + 8;
    contex.arc(size / 2 + 4, size / 2 + 4, size / 2, 0, 2 * Math.PI); //画出圆;
    contex.clip(); //裁剪上面的圆形
    if (flag) {
      contex.drawImage(img, 0, img.height / 2 - size / 2, size, size, 4, 4, size, size); // 在刚刚裁剪的园上画图
    } else {
      contex.drawImage(img, img.width / 2 - size / 2, 0, size, size, 4, 4, size, size); // 在刚刚裁剪的园上画图
    }
    contex.save();
    contex.beginPath();
    contex.strokeStyle = color;
    contex.lineWidth = 8;
    contex.arc(canvas.width / 2, canvas.width / 2, canvas.width / 2 - 8, 0, 2 * Math.PI);
    contex.stroke();
    contex.restore();
    contex.strokeStyle = "#fff";
    contex.lineWidth = 4;
    contex.arc(canvas.width / 2, canvas.width / 2, canvas.width / 2 - 10, 0, 2 * Math.PI);
    contex.stroke();
    return canvas.toDataURL();
  };
  getOtionTem = (option, list) => {
    let options = this.state.options;
    let data = [];
    let linksArr = [];
    let imgSrcArr = [];
    let newArr = [
      {
        name: option.personName || option.aid,
        symbolSize: 100,
        ...option
      }
    ];
    if (option.portraitPictureUrl) {
      imgSrcArr.push({ url: option.portraitPictureUrl, type: "person", id: option.personName || option.aid });
    } else {
      if (Array.isArray(option.aidPictureInfos)) {
        if (!!option.aidPictureInfos.length) {
          imgSrcArr.push({
            url: option.aidPictureInfos[0].newestPictureUrl || option.aidPictureInfos[0].lastestPictureUrl,
            type: "person",
            id: option.personName || option.aid
          });
        } else {
          imgSrcArr.push({ url: undefined, type: "person", id: option.personName || option.aid });
        }
      } else {
        imgSrcArr.push({ url: undefined, type: "person", id: option.personName || option.aid });
      }
    }
    list.map(v => {
      const items = newArr.filter(v2 => v2.personName === v.personName);
      if (items.length > 0) {
        newArr.push({
          ...v,
          name: `${v.personName || ""}(${items.length})`
        });
      } else {
        newArr.push({ ...v, name: v.personName || v.accoompanyAid });
      }
      // 0 同屋  1 同行  2 车辆 3 设备 4 唱去地
      switch (v.dataType) {
        case 0:
          imgSrcArr.push({ url: v.portraitPictureUrl, type: "person", id: v.personName });
          break;
        case 1:
          imgSrcArr.push({ url: v.newestPictureUrl || v.lastestPictureUrl, type: "person", id: v.personName });
          break;
        case 2:
          imgSrcArr.push({ url: v.portraitPictureUrl, type: "vehicle", id: v.personName });
          break;
        case 3:
          imgSrcArr.push({ url: v.portraitPictureUrl, type: "equipment", id: v.personName });
          break;
        case 4:
          imgSrcArr.push({ url: undefined, type: "palce", id: v.personName });
          break;
        default:
          return;
      }
    });
    newArr.forEach(v => {
      data.push({
        name: v.name,
        label: {
          normal: {
            show: v.accoompanyAid ? false : true
          }
        },
        ...v
      });
      let item = {
        source: option.personName || option.aid,
        target: v.name,
        dataType: v.dataType,
        sourceAid: option.aid,
        isLabel: v.accoompanyAid ? true : false,
        personId: v.personId,
        aid: v.accoompanyAid ? v.accoompanyAid : undefined
      };
      item.name = "同屋";
      if (v.accoompanyAid) {
        item.name = "同行";
      }
      if (v.dataType === 2) {
        item.name = "名下车辆";
      }
      if (v.dataType === 3) {
        item.name = v.labelLine;
        item.firstCaptureTime = v.firstCaptureTime;
        item.snapType = v.snapType;
      }
      if (v.dataType === 4) {
        item.name = v.labelLine;
        item.placeName = v.placeName;
        item.placeType = v.placeType;
        item.placeId = v.placeId;
        item.days = v.days;
        item.count = v.count;
      }
      linksArr.push(item);
    });
    let imgPathArr = imgSrcArr.map((v, index) => {
      return Utils.catchPromise(this.getImgData(v, index === 0 ? "#ffaa00" : undefined));
    });
    try {
      Promise.all(imgPathArr)
        .then(resArr => {
          try {
            resArr.map((path, index) => {
              if (path) {
                data[index].symbol = `image://${path}`;
              }
            });
            options.series[0].data = data;
            options.series[0].links = linksArr;
          } catch (e) {
            console.error(e);
          }
          this.setState({
            options
          });
        })
        .catch(e => {});
    } catch (e) {
      console.error(e);
    }
  };
  goPage = (moduleName, data) => {
    this.props.tab.goPage({
      moduleName,
      location: this.props.location,
      data,
      isUpdate: false
    });
  };
  async clickEchartsPie(e) {
    const { changeCOllModal, tab, location, jumpVehicleDetail, user, place } = this.props;
    const data = e.data;
    if (data.symbolSize === 100 && !data.isLabel) {
      return;
    }
    if (data.dataType === 2 && !data.vehiclePlateNo) {
      return;
    }
    if (e.dataType === "node") {
      if (data.dataType === 0 || data.dataType === 1) {
        // TODO 同屋跳转有待验证  报错是可以理解的
        const aidData = e.data.accoompanyAid || "",
          personIdData = e.data.personId || "";
        Service.person.queryPersonBindInfo({ aid: aidData, personId: personIdData }).then(res => {
          let { personId = personIdData, aids = [] } = res.data;
          let moduleName = "",
            id = "",
            type = "";
          const AIDS_LEN = aids.length > 0;
          if (personId && AIDS_LEN) {
            id = personId;
            type = "ploy";
          }
          if (personId && !AIDS_LEN) {
            id = personId;
            type = "entry";
          }
          if (!personId && !AIDS_LEN) {
            id = aidData;
            type = "aid";
          }

          moduleName = "objectMapPersonnelDetail";
          tab.openDetailPage({
            moduleName,
            value: id,
            data: { id },
            beforeAction: async () => {
              await window.LM_DB.add("parameter", { id, url: e.data.personInfoUrl, type });
            }
          });
        });
        return;
      }
      if (data.dataType === 3) {
        const placeIdRes = await Service.organization.getPlaceIdsByUserId({
          organizationId: user.userInfo.organizationId
        });
        let placeIds = placeIdRes.data.placeIds.filter(v => !!v);

        if (
          placeIds.includes(
            BaseStore.device.deviceList.find(
              v => v.cid == data.personId
            ).placeId
          )
        ){
          this.goPage("deviceFile", { id: data.personId });
        }else{
          message.warn('无该设备档案权限')
          return;
        }
      }
      if (data.dataType === 4) {
        Service.organization
          .getPlaceIdsByUserId({
            organizationId: user.userInfo.organizationId
          })
          .then(res => {
            let placeArray = place.getPlaceInfoByIds(res.data.placeIds);
            let placeData = placeArray.find(v => v.placeId === data.placeId);
            if (placeData) {
              const id = Utils.uuid();
              window.LM_DB.add("parameter", {
                id,
                placeId: data.placeId
              }).then(() => {
                tab.goPage({
                  moduleName: "personnelCompositionAnalysis",
                  location,
                  data: {
                    id:data.placeId
                  }
                });
              });
            } else {
              message.warn("您当前未被分配该场所的查看权限");
            }
          });
      }
      if (data.dataType === 2) {
        jumpVehicleDetail(data.vehiclePlateNo);
      }
    } else {
      if (data.dataType === 1) {
        changeCOllModal && changeCOllModal(2, data);
      }
      if (data.dataType === 3) {
        data.snapType && this.props.showSnapList(e);
      }
      if (data.dataType === 4) {
        changeCOllModal && changeCOllModal(1, data);
      }
    }
  }
  render() {
    return (
      <div>
        <EchartsReact
          option={this.state.options}
          style={{ height: "530px", width: "100%" }}
          ref={this.echartRef}
          onEvents={{ click: this.clickEchartsPie.bind(this) }}
        />
      </div>
    );
  }
}

export default PersonRoom;
