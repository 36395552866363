import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

const EchartsReact = Loader.loadBaseComponent('EchartsReact')

@Decorator.errorBoundary
@observer
class CloudResource extends Component {
  getOtionTem() {
    const { data } = this.props;
    const avgCount = data.countAvgPerson || [],
      count = data.countPerson || [];
    const now = moment(
      moment()
        .subtract(7, 'd')
        .format('YYYY MM DD')
    ).valueOf();
    let dataCount = count.filter(v => moment(v.date).valueOf() > now);
    const avgC = [];
    for (let i = 0; i < 7; i++) {
      const date = moment()
        .subtract(6 - i, 'd')
        .format('YYYY-MM-DD');
      avgC.push({ date, total: 0 });
    }
    avgC.forEach(v => {
      const FIND = dataCount.find(item => item.date === v.date);
      if (FIND) {
        v.total = FIND.total;
      }
    });
    const arr = avgC.map(v => v.total);
    let maxNum = Math.max(...arr);
    let base = maxNum > 10000 ? 10000 : maxNum > 1000 ? 1000 : 1;
    let qu = maxNum > 10000 ? '万' : maxNum > 1000 ? '千' : '';
    let dataA = arr.map(v => v / base);
    let nowWeeks = moment().format('E');
    //近一周的日期数组
    const weekDays = [Utils.getDay(-6), Utils.getDay(-5), Utils.getDay(-4), Utils.getDay(-3), Utils.getDay(-2), Utils.getDay(-1), Utils.getDay(0)];
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        confine: true
      },
      grid: {
        x: 50,
        y: 10,
        x2: 30,
        y2: 60,
        borderWidth: 0
      },
      legend: {
        orient: 'horizontal',
        icon: 'rect',
        itemGap: 6,
        itemWidth: 20,
        itemHeight: 4,
        bottom: 10,
        data: [
          {
            name: '人数',
            textStyle: { color: '#666' }
          }
        ]
      },
      xAxis: {
        type: 'value',
        name: qu,
        minInterval: base < 1000 ? 1 : '',
        nameLocation: 'end',
        boundaryGap: [0, 0.05],
        axisTick: false,
        axisLine: {
          show: true,
          lineStyle: {
            color: ['rgba(108,104,163,0.30)']
          }
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: '#666'
          }
        },
        splitLine: {
          lineStyle: {
            color: ['rgba(108,104,163,0.30)']
          }
        }
      },
      yAxis: {
        type: 'category',
        data: weekDays,
        axisTick: false,
        axisLine: {
          show: false
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: '#666'
          }
        }
      },
      series: [
        {
          barWidth: 6,
          barGap: 0.5,
          name: '人数',
          type: 'bar',
          label: {
            emphasis: {
              show: true,
              position: 'right',
              textStyle: {
                fontSize: '12',
                color: '#5A60A2'
              }
            }
          },
          data: dataA,
          itemStyle: {
            normal: { color: '#5A60A2' }
          }
        }
      ]
    };
    return option;
  }

  render() {
    return <EchartsReact option={this.getOtionTem()} style={{ height: 'calc(100% )' }} />;
  }
}
export default CloudResource;
