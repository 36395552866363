import React, { Component } from "react";
import { observer } from "mobx-react";
import * as _ from "lodash";
import './index.less';

const EchartsReact = Loader.loadBaseComponent('EchartsReact');
const IconFont = Loader.loadBaseComponent("IconFont");

@Decorator.errorBoundary
@observer
class CommunityResourceChart extends Component {
  getOtionTem() {
    let { data, myColor,startRadius,endRadius} = this.props;
    const option = {
      color: myColor,
      series: [
        {
          type: "pie",
          symbol: "circle",
          radius: [startRadius,endRadius],
          center: ["50%", "50%"],
          hoverAnimation: false,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: data
        }
      ]
    };
    return option;
  }

  render() {
    let { myColor, filterData,resource } = this.props;
    return (
      <div className="chart community-resoures-chart">
        <EchartsReact
          option={this.getOtionTem()}
          style={{ height: "100px", width: "100px" }}
        />
        <div className={`community-legends ${resource?"resource":''}`} style={{ width: "180px" }}>
          {filterData &&
            filterData.map((v, x) => (
              <div key={x} className="out-style">
              <IconFont type={v.icon} theme="outlined" className="icon-size"/>
                <i
                  style={{ backgroundColor: myColor[x] }}
                  className="symbolC"
                />
                <span className="name" title={v.name}>{Utils.getSubStr(v.name)}</span>
                <span className="community-chart-right-num">{v.value ?parseFloat(v.value).toLocaleString(): 0 }</span>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
export default CommunityResourceChart;
