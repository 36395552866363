(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobxReact"), require("moment"), require("_"), require("antd"));
	else if(typeof define === 'function' && define.amd)
		define("Statistics", ["React", "mobxReact", "moment", "_", "antd"], factory);
	else if(typeof exports === 'object')
		exports["Statistics"] = factory(require("React"), require("mobxReact"), require("moment"), require("_"), require("antd"));
	else
		root["Statistics"] = factory(root["React"], root["mobxReact"], root["moment"], root["_"], root["antd"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 