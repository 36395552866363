import React, { Component } from 'react';
import { observer } from 'mobx-react';
import "./index.less"

const EchartsReact = Loader.loadBaseComponent('EchartsReact');

@Decorator.errorBoundary
@observer
class ObjectMapAge extends Component {
  state = {
    total: 0,
    data: [
      { value: 0, name: '小孩' },
      { value: 0, name: '青年' },
      { value: 0, name: '中年' },
      { value: 0, name: '老人' },
    ],
    option:{},
    myColor: ['#FF8800 ', '#ffba00', '#b5bbc7', '#d8dce3']
  };

  componentWillMount() {
   const { data = [] } = this.props;
   this.getData(data);
  }
  componentWillReceiveProps(nextPorps) {
    if(nextPorps.data !== this.props.data) {
      this.getData(nextPorps.data);
    }
  }

  getValue = data => {
    let count = 0;
    data &&
      data.map(v => {
        count += v.num;
      });
    return count;
  };

  getData(data) {
    let arr = [0,0,0, 0];
    const CHILDREN_CODE = ['100801', '118401'], YOUNG_CODE = ['100802', '118402'], MID_CODE = ['100803', '118403' ], OLD_CODE = ['100804', '118404']
    data.forEach(v => {
     if(CHILDREN_CODE.includes(v.tag)) {
       arr[0] += v.count
     }
     if(YOUNG_CODE.includes(v.tag)) {
       arr[1] += v.count
     }
     if(MID_CODE.includes(v.tag)) {
       arr[2] += v.count
     }
     if(OLD_CODE.includes(v.tag)) {
       arr[3] += v.count
     }
    })
    this.getOtionTem(arr);
  }

  getOtionTem(arr) {
    let { data, myColor } = this.state;
    const total = arr.reduce((a, b) => a + b);
    data.map((v, index) => {
      v.value = arr[index];
      v.percentData = 100 * arr[index] / total
    });
    const option = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: function(params) {
          if (params.dataIndex == 0) {
            return `${params.name} : <br/>${params.value} (${
              data[0].percentData ? data[0].percentData.toFixed(2) : 0
            }%)`;
          } else if (params.dataIndex == 1) {
            return `${params.name} : <br/>${params.value} (${
              data[1].percentData ? data[1].percentData.toFixed(2) : 0
            }%)`;
          } else if (params.dataIndex == 2) {
            return `${params.name} : <br/>${params.value} (${
              data[2].percentData ? data[2].percentData.toFixed(2) : 0
            }%)`;
          } else if (params.dataIndex == 3) {
            return `${params.name} : <br/>${params.value} (${
              data[2].percentData ? data[2].percentData.toFixed(2) : 0
            }%)`;
          }
        }
      },
      color: myColor,
      series: [
        {
          type: 'pie',
          symbol: 'circle',
          radius: ['30%', '75%'],
          center: ['48%', '50%'],
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: data
        },
        {
          type: 'pie',
          radius: ['75%', '85%'],
          center: ['48%', '50%'],
          label: {
            normal: {
              show: false,
              textStyle: {
                fontSize: 24,
                color: '#FFAA00'
              }
            }
          },
          animation: false,
          labelLine: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              opacity: 0.3
            }
          },
          data: data
        }
      ]
    };
    this.setState({
      option,
      total
    })
  }

  render() {
    let { data, myColor, total, option } = this.state;
    return (
      <div className="object-map-age">
        <EchartsReact
          option={option}
          style={{ height: 'calc(100% - 32px)', width: '60%' }}
        />
        <div
          className="legends"
          style={{ height: 'calc(100% - 32px)', width: '40%' }}
        >
          {data.map((v, x) => (
            <div key={x}>
              <i style={{ backgroundColor: myColor[x] }} className="symbolC" />
              <span className="name">{v.name} ： </span>
              <div className="num">
                {Utils.splitNum(v.value)}({v.value ? ((v.value / total) * 100).toFixed(2): 0}
                %)
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default ObjectMapAge;
